<template>
  <div v-if="!displayTime">
    <button
        id="zButton"
        :disabled="!isConfirmed || isClockOutDisabled || saving"
        class="button-27"
        @click="handleTimer"
    >
      {{ title }}
    </button>
  </div>
  <div v-else style="display: flex; align-items: center;">
    <button
        v-if="showClearButton"
        :disabled="!isConfirmed || isClockOutDisabled"
        class="button-44"
        @click="clearTime"
    >
      X
    </button>
    <div style="display: flex; margin-left: 7px;">
      {{ displayTime }}
    </div>
    <button
        v-if="displayTime && !isTiming"
        class="edit-button"
        @click="editTime"
    >
      ✏️
    </button>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      title: '',
      displayTime: '',
      colField: '',
      timeOutValue: '',
      timeIn: '',
      isConfirmed: this.params.data?.confirmed,
      saving: false
    };
  },
  mounted() {
    this.initializeComponent();
  },
  computed: {
    showClearButton() {
      return !(this.colField === 'timeIn' && this.timeOutValue);
    },
    isClockOutDisabled() {
      return this.colField === 'timeOut' && !this.timeIn;
    },
    // If colField is 'timeIn' and we haven't clocked out (timeOut is empty),
    // we consider it "timing up". So no manual edits allowed during that time.
    isTiming() {
      return this.colField === 'timeIn' && !this.timeOutValue;
    }
  },
  methods: {
    initializeComponent() {
      this.updateLocalData(this.params);
    },
    setTitleBasedOnColumn(columnName) {
      this.title = columnName === 'timeIn' ? 'Clock In' : 'Clock Out';
    },
    updateLocalData(params) {
      this.colField = params.colDef.field;
      this.setTitleBasedOnColumn(this.colField);
      this.updateDisplayTime(params.value);
      if(params.data) {
        this.timeOutValue = params.data['timeOut'] || '';
        this.timeIn = params.data['timeIn'] || '';
        this.isConfirmed = params.data['confirmed'];
      }
      this.saving = false;
    },
    convertUTCtoLocal(utcTime) {
      const utcDate = new Date(utcTime);
      if (isNaN(utcDate.getTime())) throw new Error('Invalid Date');
      return utcDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
    },
    updateDisplayTime(value) {
      if (value) {
        this.displayTime = this.convertUTCtoLocal(value);
      } else {
        this.displayTime = '';
      }
    },
    clearTime() {
      this.params.node.setDataValue(this.colField, null);
      this.displayTime = '';
      if (this.colField === 'timeOut') {
        this.timeOutValue = '';
      } else if (this.colField === 'timeIn') {
        this.timeIn = '';
      }
    },
    async handleTimer() {
      try {
        this.saving = true;
        const currentDate = new Date();
        const utcTime = currentDate.toISOString();
        this.params.node.setDataValue(this.colField, utcTime);
        this.displayTime = this.convertUTCtoLocal(utcTime);
        if (this.colField === 'timeOut') {
          this.timeOutValue = utcTime;
        } else if (this.colField === 'timeIn') {
          this.timeIn = utcTime;
        }
      } catch (error) {
        console.error('Error setting time:', error);
      } finally {
        this.saving = false;
      }
    },

    // Pencil button click -> manually edit the time
    editTime() {
      try {
        const oldUtc = this.params.data[this.colField];
        if (!oldUtc) return; // No time set yet, just in case.

        // Prompt the user for a new local time, e.g. "10:03:45 PM"
        const userInput = window.prompt(
            'Enter the new time (e.g., "10:03:45 PM"):',
            this.displayTime
        );
        if (!userInput) return; // User canceled.

        // Get the stored UTC date and build a local date/time string to parse
        const oldDate = new Date(oldUtc);       // The old UTC date
        const dateStr = oldDate.toLocaleDateString(); // Keep same local date

        // Attempt to parse the new time in the context of the original date
        const newLocalDateTime = new Date(`${dateStr} ${userInput}`);
        if (isNaN(newLocalDateTime.getTime())) {
          alert('Invalid time format. Please try again.');
          return;
        }

        // Convert local date/time back to UTC for storage
        const newUtcTime = newLocalDateTime.toISOString();

        this.params.node.setDataValue(this.colField, newUtcTime);
        this.updateDisplayTime(newUtcTime);
        if (this.colField === 'timeOut') {
          this.timeOutValue = newUtcTime;
        } else if (this.colField === 'timeIn') {
          this.timeIn = newUtcTime;
        }
      } catch (error) {
        console.error('Error editing time:', error);
      }
    },

    refresh(params) {
      this.updateLocalData(params);
      return true;
    }
  }
};
</script>

<style>
.button-27 {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 6px;
  box-sizing: border-box;
  color: #009fff;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
  transform: translateY(-3px);
}

.button-27:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-4px);
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}

.button-44 {
  background: #e62143;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  text-underline-offset: 1px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  height: 100%;
  border: 0;
}

.button-44:active,
.button-44:focus {
  box-shadow: rgba(0, 0, 0, 0.3) 0 3px 3px inset;
  outline: 0;
}

.button-44:hover {
  transform: scale(1.05);
}

.button-44:disabled {
  pointer-events: none;
  opacity: 0.6;
}

/* Pencil edit button styling */
.edit-button {
  margin-left: 8px;
  background: #5A5A5A;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.edit-button:hover {
  background: #454545;
}
</style>
