<template>
  <div>
    <input
        type="checkbox"
        v-model="currentValue"
        :disabled="!isEditable"
        @change="valueChanged"
    />
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      currentValue: false,
      isEditable: true
    };
  },
  beforeMount() {
    if (!this.params || !this.params.data) {
      console.warn("params is undefined or missing data", this.params);
      return;
    }
    let columnName = this.params.colDef?.field;

    this.currentValue = !!this.params?.data[columnName];

    if (columnName === 'confirmed') {
      this.isEditable = true;
    } else {
      const getConfirmed = this.params?.getConfirmed;
      this.isEditable = getConfirmed ? getConfirmed(this.params) : false;
    }
  },
  methods: {
    valueChanged() {
      if(this.currentValue){
        this.params.node.setDataValue(this.params.colDef.field, this.currentValue);
      }
    },
    toggleCheckbox() {
      if (this.isEditable) {
        this.currentValue = !this.currentValue;
        this.valueChanged();
      }
    }
  }
};
</script>
