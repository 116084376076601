<template>
  <AgGridVue
      :allowDragFromColumnsToolPanel="false"
      :columnDefs="columnDefs"
      :components="frameworkComponents"
      :context="gridContext"
      :defaultColDef="defaultColDef"
      :floatingFilter="true"
      :getRowId="getRowId"
      :gridOptions="gridOptions"
      :theme="myTheme"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      @paginationChanged="onPaginationChanged"
      animateRows="true"
      class="myGrid"
      @cellValueChanged="getCellChange"
      @gridReady="onGridReady"
  />
  <AddRowModal
      :visible="showAddRowModal"
      @close="closeAddRowModal"
      @save="handleSaveNewRow"
  />

</template>

<script>
import {ModuleRegistry} from "ag-grid-community";
import {AllEnterpriseModule, LicenseManager} from "ag-grid-enterprise";
import {AgGridVue} from "ag-grid-vue3";
import {themeQuartz} from 'ag-grid-community';

import timeSlotRenderer from "../grid/cellRenderers/timeSlotRenderer.vue";
import booleanCellRenderer from '../grid/cellRenderers/booleanCellRenderer.vue';
import dateCellRenderer from "../grid/cellRenderers/dateCellRenderer.vue";
import timeCellRendererCustom from '../grid/cellRenderers/timeCellRendererCustom.vue';
import loadTimeCellRenderer from '../grid/cellRenderers/loadTimeCellRenderer.vue';
import archiveCellRenderer from '../grid/cellRenderers/archiveCellRenderer.vue';
import utcDateRenderer from '../grid/cellRenderers/utcDateRenderer.vue';
import shipDateRenderer from '../grid/cellRenderers/shipDateRenderer.vue';
import AddRowModal from '../modals/AddRowModal.vue';
import dateComparator from '../../utils/DateFilterComparator';
import StatusDropdownFilter from '../grid/StatusDropdownFilter.vue';
import StatusFloatingFilter from '../grid/StatusFloatingFilter.vue';
import {useMobileDetect} from '@/composables/useMobileDetect';

LicenseManager.setLicenseKey("your License Key");
ModuleRegistry.registerModules([AllEnterpriseModule]);

const timeRanges = [
  {
    text: '',
    value: null
  },
  {
    text: '8am - 9am',
    value: "08:00-09:00"
  },
  {
    text: '9am - 10am',
    value: "09:00-10:00"
  },
  {
    text: '10am - 11am',
    value: "10:00-11:00"
  },
  {
    text: '11am - 12pm',
    value: "11:00-12:00"
  },
  {
    text: '12pm - 1pm',
    value: "12:00-13:00"
  },
  {
    text: '1pm - 2pm',
    value: "13:00-14:00"
  },
  {
    text: '2pm - 3pm',
    value: "14:00-15:00"
  },
  {
    text: '3pm - 4pm',
    value: "15:00-16:00"
  }
]
// eslint-disable-next-line no-unused-vars
const quartzThemeBase = {
  accentColor: "#087AD1",
  backgroundColor: "#FFFFFF",
  borderColor: "#D7E2E6",
  borderRadius: 2,
  browserColorScheme: "light",
  cellHorizontalPaddingScale: 0.16,
  chromeBackgroundColor: {
    ref: "backgroundColor"
  },
  columnBorder: false,
  fontFamily: {
    googleFont: "Inter"
  },
  fontSize: 13,
  foregroundColor: "#555B62",
  headerBackgroundColor: "#D9D9D9",
  headerFontSize: 13,
  headerFontWeight: 400,
  headerTextColor: "#141414",
  headerVerticalPaddingScale: 1,
  oddRowBackgroundColor: "#0000FF0F",
  rowBorder: true,
  rowVerticalPaddingScale: 0.8,
  sidePanelBorder: true,
  spacing: "2px",
  wrapperBorder: false,
  wrapperBorderRadius: 2
};

const quartzExcel = {
  // Use the Excel brand green, or pick your own
  accentColor: "#217346",

  // Keep background white
  backgroundColor: "#FFFFFF",

  // Subtle gray border color for grid lines
  borderColor: "#D0D7D0",

  // Keep corners slightly rounded (feel free to set to 0 for sharp corners)
  borderRadius: 2,

  // Force light mode
  browserColorScheme: "light",

  // Slight horizontal padding
  cellHorizontalPaddingScale: 0.5,

  // Inherit chrome background from the base background
  chromeBackgroundColor: {ref: "backgroundColor"},

  // Turn on both row and column borders to mimic Excel grid lines
  rowBorder: true,
  columnBorder: true,

  // Use a standard, easy-to-read font. Inter is fine, or "Arial" as an alternative
  fontFamily: {googleFont: "Inter"},

  // Slightly smaller text (Excel-like)
  fontSize: 12,
  foregroundColor: "#000000",

  // Subtle gray header background with bold black text
  headerBackgroundColor: "#D9D9D9",
  headerFontSize: 12,
  headerFontWeight: 700,
  headerTextColor: "#000000",

  // Slightly smaller header vertical padding
  headerVerticalPaddingScale: 0.5,

  // Banded rows: light gray for odd rows
  oddRowBackgroundColor: "#F3F3F3",

  // Decrease row vertical padding for a tighter table
  rowVerticalPaddingScale: 0.6,

  // Side panel border on
  sidePanelBorder: true,

  // Minimal spacing
  spacing: "2px",

  // Optional outer wrapper styling
  wrapperBorder: true,
  wrapperBorderRadius: 2
};
export default {
  name: "ImAgGrid",
  components: {
    AgGridVue,
    AddRowModal
  },
  emits: [
    'saveCellChange',
    'saveNewRow',
    'gridReady',
    'updateDisplayedRowCount'
  ],
  data() {
    return {
      myTheme: themeQuartz
          .withParams(quartzExcel),
      suppressSave: false,
      showAddRowModal: false,
      gridApi: null,
      gridColumnApi: null,
      displayedRowCount: 0,
      columnDefs: [
        // {
        //   headerName: "Row",
        //   field: "row",
        //   valueGetter: "node.rowIndex + 1",
        //   width: 20,
        //   pinned: 'left'
        // },
        {
          headerName: "Confirmed",
          field: "confirmed",
          cellRenderer: 'booleanCellRenderer',
          width: 20,
          pinned: 'left'
        },
        {
          headerName: "Karavan Load #",
          field: "loadNo",
          filter: 'agTextColumnFilter',
          pinned: 'left'
        },
        {
          headerName: "DLX Load #",
          editable: params => params.data?.confirmed,
          field: "dlxLoadNo",
          filter: 'agTextColumnFilter',
          pinned: 'left',
          cellStyle: params => {
            if (params.data?.readyDate) {
              const dueDate = new Date(params.data.dueDate);
              const currentDate = new Date();
              return { backgroundColor: '#90EE90', color: currentDate < dueDate ? 'black' : '#bd2222' };
            }
            return {};
          }
        },
        {
          headerName: "Customers",
          field: "customer"
        },
        {
          headerName: "Final Stop",
          field: "name",
          valueGetter: params => {
            if (!params.data?.finalStopName && !params.data?.finalStopCS) {
              return '';
            }
            return `${params.data.finalStopName} (${params.data.finalStopCS})`;
          },
          width: 460,
        },
        {
          headerName: "Due Date",
          field: "dueDate",
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator
          },
          cellRenderer: 'utcDateRenderer',
          cellClass: 'centered-cell',
          comparator: (date1, date2) => {
            return new Date(date1) - new Date(date2);
          }
        },
        {
          headerName: "Created Date",
          field: "createDate",
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: dateComparator
          },
          cellRenderer: 'utcDateRenderer',
          cellClass: 'centered-cell',
          comparator: (date1, date2) => {
            return new Date(date1) - new Date(date2);
          }
        },
        {
          headerName: "Load Revenue",
          field: "loadRevenue",
          editable: params => params.data?.confirmed,
          cellRenderer: params => {
            const value = params.value;
            if (!value) return "";
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(parseFloat(value).toFixed(2));
          },
          cellStyle: {textAlign: 'center', paddingRight: '10px'},
        },
        {
          headerName: "Load Cost",
          field: "loadCost",
          editable: params => params.data?.confirmed,
          cellRenderer: params => {
            const value = params.value;
            if (!value) return "";
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(parseFloat(value).toFixed(2));
          },
          cellStyle: params => {
            const loadRevenue = parseFloat(params.data?.loadRevenue || 0).toFixed(2);
            const loadCost = parseFloat(params.data?.loadCost || 0).toFixed(2);

            // Compare loadCost to 7.25% of loadRevenue
            if (parseFloat(loadCost) > parseFloat(loadRevenue * 0.0725).toFixed(2)) {
              return {color: 'red', textAlign: 'center', paddingRight: '10px'};
            } else {
              return {color: 'green', textAlign: 'center', paddingRight: '10px'};
            }
          },
        },
        {
          headerName: "% of Load Revenue",
          field: "%ofLoadRevenue",
          valueGetter: params => {
            const loadRevenue = parseFloat(params.data?.loadRevenue || 0);
            const loadCost = parseFloat(params.data?.loadCost || 0);

            if (loadRevenue === 0) return null;
            return (loadCost / loadRevenue) * 100;
          },
          cellRenderer: params => {
            if (!params.value) return "";
            console.log(params.value);
            return params.value.toFixed(2) + "%";
          },
          cellStyle: params => {
            const loadRevenue = parseFloat(params.data?.loadRevenue || 0).toFixed(2);
            const loadCost = parseFloat(params.data?.loadCost || 0).toFixed(2);

            // Compare loadCost to 7.25% of loadRevenue
            if (parseFloat(loadCost) > parseFloat(loadRevenue * 0.0725).toFixed(2)) {
              return {color: 'red', textAlign: 'center', paddingRight: '10px'};
            } else {
              return {color: 'green', textAlign: 'center', paddingRight: '10px'};
            }
          },
        },
        {
          headerName: "Ready Date",
          field: "readyDate",
          filter: 'agDateColumnFilter',
          editable: params => params.data?.confirmed,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'utcDateRenderer',
          cellClass: 'centered-cell'
        },
        {
          headerName: "Orig Ship Date",
          field: "origShipDate",
          filter: 'agDateColumnFilter',
          editable: false,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'utcDateRenderer',
          cellClass: 'centered-cell'
        },
        {
          headerName: "Ship Date",
          field: "shipDate",
          filter: 'agDateColumnFilter',
          editable: params => params.data?.confirmed,
          filterParams: {
            comparator: dateComparator
          },
          cellEditor: 'dateCellRenderer',
          cellRenderer: 'shipDateRenderer',
          cellClass: 'centered-cell'
        },
        {
          headerName: "Time Slot",
          field: "timeSlot",
          cellEditor: 'agSelectCellEditor',
          editable: params => params.data?.confirmed,
          cellEditorParams: {
            values: timeRanges.map(item => item.value) // Map values only for the dropdown
          },
          valueFormatter: params => {
            // Find the corresponding label for the value
            const range = timeRanges.find(item => item.value === params.value);
            return range ? range.text : params.value;
          },
          valueParser: params => {
            // The selected value is directly saved
            return params.newValue;
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Notes",
          field: "notes",
          editable: params => params.data?.confirmed,
          cellEditor: 'agLargeTextCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            maxLength: 255
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Equipment",
          field: "equipment",
          editable: params => params.data?.confirmed,
          cellClass: 'centered-cell'
        },
        {
          headerName: "Carrier Name & Notes",
          field: "truck",
          editable: params => params.data?.confirmed,
          cellClass: 'centered-cell'
        },
        {
          headerName: "QC",
          field: "qc",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data?.confirmed
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Scanned",
          field: "paperwork",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data?.confirmed
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Staged",
          field: "staged",
          width: 100,
          suppressHeaderMenuButton: true,
          cellRenderer: 'booleanCellRenderer',
          cellRendererParams: {
            getConfirmed: (params) => params.data?.confirmed
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Time IN",
          field: "timeIn",
          cellRenderer: 'timeCellRendererCustom',
          cellRendererParams: {
            getConfirmed: (params) => params.data?.confirmed
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Time OUT",
          field: "timeOut",
          cellRenderer: 'timeCellRendererCustom',
          cellRendererParams: {
            getConfirmed: (params) => params.data?.confirmed
          },
          cellClass: 'centered-cell'
        },
        {
          headerName: "Load Time",
          field: "loadTime",
          cellRenderer: 'loadTimeCellRenderer',
          cellClass: 'centered-cell'
        },
        {
          headerName: 'Status',
          field: 'status',
          pinned: 'right',
          editable: params => params.data?.confirmed,
          cellRenderer: 'archiveCellRenderer',
          filter: StatusDropdownFilter,
          floatingFilter: true,
          floatingFilterComponent: StatusFloatingFilter,
          width: 20,
          cellClass: 'centered-cell'
        }
      ],
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        resizable: true,
        minWidth: 100,
        enableCellChangeFlash: true,
        enableRowGroup: true
      },
      frameworkComponents: {
        timeSlotRenderer,
        timeCellRendererCustom,
        booleanCellRenderer,
        dateCellRenderer,
        loadTimeCellRenderer,
        archiveCellRenderer,
        utcDateRenderer,
        shipDateRenderer
      },
      gridOptions: {
        sideBar: {
          defaultToolPanel: '',
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressValues: true
              }
            },
            {
              id: "filters",
              labelDefault: "Filters",
              labelKey: "filters",
              iconKey: "filter",
              toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        enableRangeSelection: true,
        getContextMenuItems: () => {
          // Common default items
          const defaultItems = [
            'cut',
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            'export',
          ];

          // Our custom item
          defaultItems.push({
            name: 'Print Selected Cells',
            action: () => {
              // We call our method to print
              this.printSelectedCells();
            },
          });

          return defaultItems;
        },
        suppressColumnVirtualisation: false,
        onCellKeyDown: function (event) {
          if (event.event.key === ' ' || event.event.key === 'Enter') {
            const cellRendererInstances = event.api.getCellRendererInstances({
              rowNodes: [event.node],
              columns: [event.column],
            });

            cellRendererInstances.forEach(function (cellRendererInstance) {
              if (event.node.data?.confirmed) {
                if (cellRendererInstance && cellRendererInstance.displayTime && cellRendererInstance.showClearButton && cellRendererInstance.clearTime) {
                  event.event.preventDefault();
                  cellRendererInstance.clearTime();
                } else if (cellRendererInstance && !cellRendererInstance.displayTime && cellRendererInstance.handleTimer) {
                  event.event.preventDefault();
                  cellRendererInstance.handleTimer();
                } else if (cellRendererInstance && cellRendererInstance.toggleCheckbox) {
                  event.event.preventDefault();
                  cellRendererInstance.toggleCheckbox();
                }
              }
            });
          }
        },
        getRowStyle: function (params) {
          if (params.data) {
            if (params.data?.status === 'removed') {
              return {
                background: '#ff6666',
                color: '#f6f6f6'
              };
            } else if (params.data.dueDate) {
              const dueDate = new Date(params.data.dueDate);
              const currentDate = new Date();
              if (dueDate < currentDate) {
                return {
                  color: '#bd2222'
                };
              }
            }
          }

        }
      },
      gridContext: null,
      isMobile: null
    };
  },
  created() {
    this.gridContext = {componentParent: this};
    this.isMobile = useMobileDetect();
  },
  methods: {
    printSelectedCells() {
      if (!this.gridApi) return;

      const ranges = this.gridApi.getCellRanges();
      if (!ranges || ranges.length === 0) {
        alert('No cells selected!');
        return;
      }

      const now = new Date();
      const formattedDateTime = now.toLocaleString();
      const docTitle = `Load Sheet - ${formattedDateTime}`;

      let completeHTML = `
    <html>
      <head><title>${docTitle}</title></head>
      <style>
        body {
          font-family: Calibri, Arial, sans-serif;
          font-size: 8pt!important;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          font-size: 8pt !important;
        }
        th, td {
          border: 1px solid black;
          padding: 1px;
          text-align: left;
          font-size: 8pt !important;
        }
        th {
          background-color: #f2f2f2;
        }

        @media print {
          body {
            font-size: 8pt !important;
          }
          table {
            font-size: 8pt !important;
          }
          th, td {
          font-size: 8pt !important;
        }
      }
    </style>
      <body>
  `;

      // Loop over ALL selected ranges
      ranges.forEach((range, index) => {
        // Calculate row indexes
        const startRowIdx = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        const endRowIdx = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

        // Grab columns in the range
        const columnsInRange = range.columns;

        // Begin building a table for this particular range
        let tableHTML = `
      <h5>Range #${index + 1}</h5>
      <table border="1" style="border-collapse:collapse;">
        <thead>
          <tr>
    `;

        // Table headers
        columnsInRange.forEach((col) => {
          const headerName = col.getColDef().headerName || col.getColId();
          tableHTML += `<th>${headerName}</th>`;
        });

        tableHTML += `
          </tr>
        </thead>
        <tbody>
    `;

        // Rows
        for (let rowIndex = startRowIdx; rowIndex <= endRowIdx; rowIndex++) {
          const rowNode = this.gridApi.getDisplayedRowAtIndex(rowIndex);
          if (!rowNode) continue;

          tableHTML += `<tr>`;

          // For each column, retrieve the cell value
          columnsInRange.forEach((col) => {
            const colDef = col.getColDef();
            let cellValue = '';

            if (typeof colDef.valueGetter === 'function') {
              // If there's a function-based valueGetter:
              cellValue = colDef.valueGetter({
                data: rowNode.data,
                node: rowNode,
                colDef: colDef,
                column: col,
                api: this.gridApi,
                columnApi: this.gridColumnApi,
              });
            } else if (colDef.field) {
              // If there's a simple field property:
              cellValue = rowNode.data ? rowNode.data[colDef.field] : '';
            }

            // Handle null/undefined
            if (cellValue == null) cellValue = '';

            // Special handling for the built-in boolean cell renderer
            if (colDef.cellRenderer === 'booleanCellRenderer') {
              const checked = cellValue === true || cellValue === 'true' ? 'checked' : '';
              cellValue = `<input type="checkbox" ${checked}>`;
            }
            // Apply any cellRenderer function if defined (other than the string type)
            else if (colDef.cellRenderer && typeof colDef.cellRenderer === 'function') {
              const rendererResult = colDef.cellRenderer({
                value: cellValue,
                data: rowNode.data,
                node: rowNode,
                colDef: colDef,
                column: col,
                api: this.gridApi,
                columnApi: this.gridColumnApi,
              });
              // If the renderer returns a DOM element, extract its HTML; otherwise, use the result directly.
              cellValue = rendererResult && rendererResult.outerHTML
                  ? rendererResult.outerHTML
                  : rendererResult;
            }

            // Get cellStyle, if defined
            let cellStyle = '';
            if (colDef.cellStyle) {
              let styleObj =
                  typeof colDef.cellStyle === 'function'
                      ? colDef.cellStyle({ value: cellValue, data: rowNode.data, node: rowNode, colDef, column: col })
                      : colDef.cellStyle;

              if (styleObj && typeof styleObj === 'object') {
                cellStyle = Object.entries(styleObj)
                    .map(([key, val]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${val};`)
                    .join(' ');
              }
            }

            console.log(cellStyle);

            tableHTML += `<td style="${cellStyle}">${cellValue}</td>`;

          });

          tableHTML += `</tr>`;
        }

        tableHTML += `
        </tbody>
      </table>
    `;

        // Append this table's HTML to the overall HTML
        completeHTML += tableHTML;
      });

      // Close out final HTML
      completeHTML += `
      </body>
    </html>
  `;

      // Open new window & print all tables
      const printWindow = window.open('', '', 'width=800,height=600');
      if (!printWindow) {
        alert('Pop-up blocked! Please allow pop-ups and try again.');
        return;
      }

      printWindow.document.write(completeHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // printWindow.close(); // optional
    },

    setSuppressSave(flag) {
      this.suppressSave = flag;
    },
    getRowId(params) {
      return params.data.loadNo.toString();
    },
    onGridReady(params) {
      this.gridApi = params.api;

      //if mobile make sure all columns are not pinned
      if (this.isMobile) {
        this.gridApi.getColumns().forEach(column => {
          column.setPinned(null);
        });
      }

      this.updateDisplayedRowCount();

      this.gridApi.addEventListener('rowDataChanged', this.updateDisplayedRowCount);
      this.gridApi.addEventListener('rowDataUpdated', this.updateDisplayedRowCount);
      this.gridApi.addEventListener('modelUpdated', this.updateDisplayedRowCount);

      const savedStateStr = localStorage.getItem('agGridState');
      if (!savedStateStr) {
        this.sortGrid();
      }

      this.$emit('gridReady', this.gridApi);
    },
    onPaginationChanged() {
      if (!this.gridApi) return;

      // Refresh displayed row count or do other updates
      this.updateDisplayedRowCount();

      // If you want to tell the parent which page we’re on, you can emit an event:
      const currentPage = this.gridApi.paginationGetCurrentPage() + 1; // 1-based
      const totalPages = this.gridApi.paginationGetTotalPages();
      this.$emit('pageChanged', { currentPage, totalPages });
    },
    openAddRowModal() {
      this.showAddRowModal = true;
    },
    closeAddRowModal() {
      this.showAddRowModal = false;
    },
    handleSaveNewRow(newRowData) {
      newRowData.confirmed = ['1', 'true', 1, true].includes(newRowData.confirmed);
      this.$emit('saveNewRow', newRowData);
    },
    sortGrid() {
      this.gridApi.getColumnFilterInstance('status').then(filterInstance => {
        if (!filterInstance) {
          console.error('Filter instance for "status" not found');
          return;
        }

        filterInstance.setModel({value: 'Active'});

        this.gridApi.onFilterChanged();

        this.gridApi.applyColumnState({
          state: [
            {
              colId: 'dueDate',
              sort: 'asc',
              sortIndex: 1,
            },
            {
              colId: 'shipDate',
              sort: 'asc',
              sortIndex: 0,
            }
          ],
          defaultState: {sort: null},
        });
      }).catch(err => {
        console.error('Error retrieving filter instance:', err);
      });
    },
    getCellChange(cell) {
      if (this.suppressSave) {
        return;
      }

      let columnName = '';
      let data = '';
      let cellHandler = cell;

      if (cell.currentValue !== undefined) {
        columnName = cell.currentColumn;
        data = Object.assign({}, cell.params.data);
        data[columnName] = cell.currentValue;
        cellHandler = cell.params;
      } else {
        columnName = cell.column.colId;
        data = Object.assign({}, cell.data);
      }

      if (columnName === 'shipDate' && !data.origShipDate) {
        data.origShipDate = data.shipDate;
        this.compileCellChange(cellHandler.data.loadNo, 'origShipDate', data.origShipDate);
      }

      this.compileCellChange(cellHandler.data.loadNo, columnName, data[columnName]);

      this.gridApi.refreshCells({force: true, suppressFlash: true, rowNodes: [cellHandler.node]});
    },
    compileCellChange(loadNo, cell, value) {
      if (this.suppressSave) return;
      const data = {
        loadNo: loadNo,
        cellName: cell,
        value: value
      };
      this.$emit('saveCellChange', data);
    },
    getLoadTime(params) {
      let loadTime = null;

      let timeIn = params.data.timeIn;
      let timeOut = params.data.timeOut;

      if (timeIn && timeOut) {
        let timeInDate = new Date(timeIn);
        let timeOutDate = new Date(timeOut);

        if (!isNaN(timeInDate) && !isNaN(timeOutDate)) {
          let timeDifference = timeOutDate.getTime() - timeInDate.getTime();

          let totalSeconds = Math.floor(timeDifference / 1000);
          let hours = Math.floor(totalSeconds / 3600);
          let minutes = Math.floor((totalSeconds % 3600) / 60);
          let seconds = totalSeconds % 60;

          loadTime = [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
          ].join(':');
        }

        params.data.loadTime = loadTime;
        this.compileCellChange(params.data.loadNo, 'loadTime', loadTime);
      }
    },
    scrollToLoadNo(loadNo) {
      if (!this.gridApi) {
        console.warn("Grid API is not ready yet.");
        return;
      }

      const rowNode = this.gridApi.getRowNode(loadNo.toString());

      if (rowNode) {
        this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'middle');

        rowNode.setSelected(true);

        console.log(`Scrolled to loadNo: ${loadNo}`);
      } else {
        console.warn(`Row with loadNo ${loadNo} not found.`);
      }
    },
    updateDisplayedRowCount() {
      if (this.gridApi) {
        let count = 0;
        this.gridApi.forEachNode(node => {
          if (node.data?.status === '') {
            count += 1;
          }
        });
        this.displayedRowCount = count;
        this.$emit('updateDisplayedRowCount', count);
        console.log("Displayed row count:", count);
      }
    },
  },
  expose: ['getLoadTime', 'closeAddRowModal', 'setSuppressSave', 'openAddRowModal'],
};
</script>

<style>
.myGrid {
  width: 100%;
}

.centered-cell {
  text-align: center;
}

</style>
